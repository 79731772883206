import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DigiPadalaAgentKNB from 'images/digipadala-agent-knb.png'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promos/DigiPadalaAgentKNB`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'DIGIPADALA AGENT KNB (February 1-28, 2022)',
  },
  {
    property: 'og:description',
    content:
      'Get P300 Digipay credits when you convert from a regular agent to a DigiPadala Agent',
  },
  {
    property: 'og:image',
    content: DigiPadalaAgentKNB,
  },
]

const Mechanics = () => {
  return (
    <>
      <h4>MECHANICS</h4>
      <div className='mechanics lead'>
        <ul>
          <li>
            Get P300 Digipay credits when you convert from a regular agent to a
            DigiPadala Agent
          </li>
          <li>
            Requirements (must have all):
            <ul>
              <li>SEC or DTI registration</li>
              <li>Mayor’s or Barangay permit</li>
              <li>
                Photos of the physical store (at least 3 including the street
                view)
              </li>
            </ul>
          </li>
          <li>Open to existing Digipay Agents only</li>
        </ul>
      </div>
      <i>
        Disclaimer: New agents who will sign up through the Referral Program and
        will provide business documents are not eligible to receive Digipay
        credits under this promotion
      </i>
      <h4>HOW TO CLAIM THE PRIZE</h4>
      <div className='mechanics'>
        <ul>
          <li>
            Digipay Team will credit the referral fee within five (5) days after
            the last day of the promo period
          </li>
        </ul>
      </div>
    </>
  )
}

const Promo4 = () => (
  <div>
    <Helmet title="Promos | DigiPadala Agent KNB?" meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <div className="promo-page">
      <h1 align="center" className="font-weight-bold">
        DIGIPADALA AGENT KNB?
      </h1>
      <Image
        src={DigiPadalaAgentKNB}
        width="100%"
        align="center"
        className="promo-image"
      />
      <Mechanics />
    </div>
    <Footer />
  </div>
)
export default Promo4
